import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useEffect, useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '@atoms/Button/Button';
import Divider from '@atoms/Divider/Divider';
import Section from '@atoms/Section/Section';
import Text from '@atoms/Text/Text';
import Title from '@atoms/Title/Title';
import AmbassadorList from '@components/AmbassadorList';
import CardAction from '@components/CardAction/CardAction';
// Modules
import Footer from '@components/Footer/Footer';
import HeaderMinimal from '@components/HeaderMinimal/HeaderMinimal';
import HeaderNav from '@components/HeaderNavV2/HeaderNav';
import Layout from '@components/layout';
import LightBanner from '@components/LightBanner/LightBanner';
import Seo from '@components/seo';
import { useLocale } from '@hooks';
import LanguageNotSupported from '@components/LanguageNotSupported';
import SingleAccordion from '@components/SingleAccordion/SingleAccordion';
import { StoryType } from '@constants/common';
import { withLocale, withTracking } from '@hocs';
import { Grid } from '@material-ui/core';
import { useTracking } from '@hooks';

export const query = graphql`
    fragment FaqFields on AEPSYCMS_Faq {
        qar(pagination: { limit: 99 }) {
            question
            answer
        }
        title
        subtitle
    }
    query($language: String!, $customLangKey: AEPSYCMS_I18NLocaleCode!) {
        locales: allLocale(
            filter: { ns: { in: ["index"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        headerMobileImage: file(
            relativePath: { eq: "ressourcen/ressourcen-header-mobile.png" }
        ) {
            childImageSharp {
                fluid(maxWidth: 375, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        headerDesktopImage: file(
            relativePath: { eq: "ressourcen/ressourcen-header.png" }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1420) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        expertInterviewPattern: file(
            relativePath: { eq: "patterns/pattern-23.png" }
        ) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 720) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        aepsyCms {
            stories(
                locale: $customLangKey
                pagination: { limit: 100 }
                sort: "createdAt:desc"
            ) {
                slug
                title
                locale
                type
                coverPicture {
                    url
                    urlSharp {
                        childImageSharp {
                            fluid(maxWidth: 300, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                author {
                    type
                    name
                    description
                    avatar {
                        url
                        urlSharp {
                            childImageSharp {
                                fluid(
                                    maxWidth: 150
                                    quality: 80
                                ) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
            articles(locale: $customLangKey, pagination: { limit: 100 }) {
                title
                description
                slug
                locale
                content
                tagline
                coverPicture {
                    url
                    urlSharp {
                        childImageSharp {
                            fluid(maxWidth: 250, quality: 80) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                author {
                    type
                    name
                    description
                }
            }
            faqLang: faqs(
                locale: $customLangKey
                filters: { key: { eq: "RESOURCE_PAGE" } }
            ) {
                ...FaqField
            }
        }
    }
`;

const RessourcenPage = ({ location, data: graphData }) => {
    const { t } = useTranslation();
    const { currentLanguage } = useLocale();
    const { trackPageView } = useTracking();
    useEffect(() => {
        trackPageView();
    }, []);

    const articlesList = graphData.aepsyCms?.articles;
    const [ambassadorList, setAmbassadorList] = useState([]);
    const [expertList, setExpertList] = useState([]);

    const faq = graphData.aepsyCms.faqLang[0]?.qar;
    const firstItem = graphData.aepsyCms.faqLang[0];
    const faqTitle = firstItem?.title || t('faq.section.title');
    const faqSubtitle =
        firstItem?.subtitle || t('faq.section.subtitle');

    useEffect(() => {
        const stories = graphData.aepsyCms.stories

        setAmbassadorList(
            stories.filter(
                (item) =>
                    item?.author &&
                    item?.authortype === StoryType.Ambassador
            )
        );
        setExpertList(
            stories.filter(
                (item) =>
                    item?.author &&
                    item?.authortype === StoryType.Expert
            )
        );
    }, [graphData, currentLanguage]);

    // GraphQL
    const header_image_sources = [
        graphData.headerMobileImage.childImageSharp.fluid,
        {
            ...graphData.headerDesktopImage.childImageSharp.fluid,
            media: `(min-width: 750px)`,
        },
    ];

    return (
        <Layout>
            <Seo
                title={t('resources.seo.title')}
                description={t('resources.seo.subtitle')}
            />
            <div className="global_theme-green">
                <HeaderNav theme="white" location={location.pathname} />
                <Section theme="light">
                    <HeaderMinimal
                        theme="light"
                        title={t('resources.header.title')}
                        btnPrimary={
                            <AnchorLink offset={() => 40} href="#mehr">
                                <Button
                                    variant="primary"
                                    theme="white"
                                    label={t('button.discover')}
                                    size="l"
                                />
                            </AnchorLink>
                        }
                        imageSrc={header_image_sources}
                    />
                </Section>

                {/* Articles */}
                <div id="mehr">
                    {ambassadorList.length > 0 ? (
                        <Section spacingTop="l">
                            <AmbassadorList list={ambassadorList} />
                        </Section>
                    ) : (
                        <Section spacingBottom="l">
                            <LanguageNotSupported />
                        </Section>
                    )}
                </div>

                {/* Introduction */}

                <>
                    {articlesList && articlesList.length > 0 ? (
                        <Section spacing="l">
                            <Section container="short">
                                <Title align="center">
                                    {t('resources.title')}
                                </Title>

                                <div className="g_1_4 g_center">
                                    <Divider spacing="m" />
                                </div>

                                <div className="g_2_3 g_center">
                                    <Text align="center">
                                        {t('resources.subtitle')}
                                    </Text>
                                </div>
                            </Section>
                            <Section container="large" spacingTop="m">
                                <Grid container spacing={4}>
                                    {articlesList.map((item, index) => {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                key={index}
                                            >
                                                <CardAction
                                                    variant="image"
                                                    ctaLink={`/ressourcen/${item?.slug}`}
                                                    image={
                                                        item?.coverPicture
                                                            ? item?.coverPicture.url
                                                            : ''
                                                    }
                                                    imageSharp={
                                                        item?.coverPicture?.urlSharp
                                                            ? item?.coverPicture.urlSharp
                                                            : ''
                                                    }
                                                >
                                                    <Title
                                                        tag="h4"
                                                        size="l"
                                                        font="alt"
                                                    >
                                                        {item?.title}
                                                    </Title>
                                                    <Text size="s">
                                                        {item?.tagline}
                                                    </Text>
                                                </CardAction>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                            </Section>
                        </Section>
                    ) : (
                        <Divider spacing="xl" invisible />
                    )}
                </>

                {/* Quote */}
                <Section container="short" spacing="l" theme="dark">
                    <div className="g_center">
                        <Title
                            align="center"
                            size="xxl"
                            theme="white"
                            font="alt"
                        >
                            {t('resources.quote')}
                        </Title>
                        {/* <Text size="l" theme="white" align="center">
							-Jiddu Krishnamurti-
						</Text> */}
                    </div>
                </Section>

                {/* Stories */}
                <div id="stories">
                    <>
                        {expertList && expertList?.length > 0 ? (
                            <Section spacing="l">
                                <Section container="short">
                                    <Title align="center">
                                        {t('resources.stories.title')}
                                    </Title>

                                    <div className="g_1_4 g_center">
                                        <Divider spacing="m" />
                                    </div>

                                    <div className="g_2_3 g_center">
                                        <Text align="center">
                                            {t('resources.stories.subtitle')}
                                        </Text>
                                    </div>
                                </Section>
                                <Section container="large" spacingTop="m">
                                    <Grid container spacing={4}>
                                        {expertList.map((item, index) => {
                                            return (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={6}
                                                    md={4}
                                                    key={item?.slug}
                                                >
                                                    <CardAction
                                                        key={index}
                                                        variant="human"
                                                        theme="provider"
                                                        ctaLink={
                                                            '/stories/' +
                                                            item?.slug
                                                        }
                                                        avatar={
                                                            item?.author &&
                                                            item?.author.avatar?.url
                                                        }
                                                        avatarSharp={
                                                            item?.author &&
                                                            item?.author.avatar?.urlSharp
                                                        }
                                                        image={
                                                            graphData
                                                                .expertInterviewPattern
                                                                .childImageSharp
                                                                .fluid.src
                                                        }
                                                    >
                                                        <Title
                                                            tag="h4"
                                                            size="l"
                                                            font="alt"
                                                        >
                                                            {`${t(
                                                                'resources.expert.interview.with'
                                                            )} ${item?.author &&
                                                            item?.author.name
                                                                }`}
                                                        </Title>
                                                        <Text size="s">
                                                            "{item?.title}"
                                                        </Text>
                                                    </CardAction>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Section>
                            </Section>
                        ) : (
                            <></>
                        )}
                    </>
                </div>
                {faq && (
                    <Section container="short" spacingTop="m" spacingBottom="m">
                        <Title align="center">{faqTitle}</Title>
                        <div className="g_1_4 g_center">
                            <Divider spacing="m" />
                        </div>
                        <Text align="center">{faqSubtitle}</Text>
                        <Section spacingTop="m">
                            <SingleAccordion
                                data={faq}
                                comingFrom="resources"
                            />
                        </Section>
                    </Section>
                )}

                {/* Self-help Tools */}
                {/*
                <div id="tools">
                    <Section container="short" spacing="l">
                        <Title align="center">Hilfe zur Selbsthilfe</Title>

                        <div className="g_1_4 g_center">
                            <Divider spacing="m" />
                        </div>

                        <div className="g_2_3 g_center">
                            <Text align="center">
                            Entdecke deinen mentalen Werkzeugkasten zum selber Anwenden. Evidenzbasierte Übungen, psychologische Tests und Hilfestellungen für den täglichen Gebrauch.
                            </Text>
                        </div>
                    </Section>
                </div>
                 */}

                {/* Footer */}
                <LightBanner
                    title={t('resources.footer.title')}
                    description={t('resources.footer.subtitle')}
                    button={
                        <a href="mailto:hey@aepsy.com">
                            <Button
                                variant="inline"
                                theme="highlighted"
                                label={t('button.contact.now')}
                                iconArrow
                            />
                        </a>
                    }
                />

                <Footer variant="extended" />
            </div>
        </Layout>
    );
};

export default withTracking(withLocale(RessourcenPage));
